import React from 'react'
import styled from 'styled-components'
import mail from '../../images/svg/mail-icon.svg'
import phone from '../../images/svg/phone-icon.svg'
import sff from '../../images/svenskfastighetsformedling.png'

const Broker = () => {
  return (
    <Container className="broker">
      <div className="head-container">
        <h3>Kontaktuppgifter</h3>
      </div>
      <div className="brokerInfo">

        <h4 style={{marginTop: "50px"}}>Ebba Jönsson</h4>
        <p>Ansvarig fastighetsmäklare<br></br>Svensk Fastighetsförmedling</p>
        <a href="tel:+46768840141"><img className="icon" src={phone} alt="phone-icon" />076 - 884 01 41</a>
        <a href="mailto:ebba.jonsson@svenskfast.se"><img className="icon" src={mail} alt="phone-icon" /> ebba.jonsson@svenskfast.se</a>

        <div className="sff">
          <img src={sff} alt="Svensk fastighetsförmedling icon" />
        </div>
      </div>
    </Container>
  )
}

const Container = styled.div`
background: rgba(44, 27, 14, 0.7);
color: #fff;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
flex-direction: column;

.head-container {
  position: absolute;
  left:20px;
  bottom:0;
  transform: rotate(270deg);
  transform-origin: 0 0;
}

.brokerInfo {
  margin-left: 100px;
  width:100%;

  h4 {
    margin-bottom:0.5rem;
    font-size: 28px;
    font-weight:500;
  }
  p {
    margin-bottom: 1rem;
  }
  p, a {
    font-size: 16px;
    line-height: 22px;
  }
  a {
    display: flex;
    justify-content:center;
  }
  .icon {
    margin-bottom: 0;
    max-width: 20px;
    margin-right: 10px;
  }
}

h3 {
  position: relative;
  font-size: 80px;
  line-height:80px;
  font-weight: 500;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: #fff;
  display:block;

  &:hover {
    text-decoration: underline;
  }
}

.sff {
  width: 100%;
  border-top: 1px solid #fff;
  max-width: 260px;
  margin: 0 auto;
  margin-top:2rem;
  img {
    margin-top:2rem;
    width: 150px;
  }
}

@media only screen and (max-width: 1250px) {
  display: block;
  padding: 2rem;
  
  .broker {
    margin-left:0;
  }

  .brokerInfo {
    margin-left: 0;
  }

  .head-container {
    transform: unset;
    position: unset;

    h3 {
      font-size: 50px;
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .head-container h3 {
    font-size: calc(30px + (100 - 30) * ((100vw - 300px) / (1600 - 300)));
  }

  .sff img {
    max-width: 100px;
  }

  .brokerInfo {
    h4 {
      font-size: 20px;
    }

    p, a {
      font-size: 16px;
      line-height: 20px;
    }

    .icon {
      width: 15px;
    }
  }

  @media only screen and (max-width: 500px) {
    padding:2rem 1rem;
  }
`;

export default Broker
