import React from "react"
import Document from "./Document"
import styled from "styled-components"
import saljbroschyr from "../../../pdf/Saljbroschyr.pdf"
import kopprocess from "../../../pdf/Kopprocess.pdf"
import rumsbeskrivning from "../../../pdf/Rumsbeskrivning.pdf"
import forkopsprocess from "../../../pdf/Forkopsprocess.pdf"
import { H2 } from "../../../styles/H2"

const DocumentList = () => {
  const pdf = {
    saljbroschyr: {
      pdf: saljbroschyr,
      title: "Säljbroschyr",
    },
    kopprocess: {
      pdf: kopprocess,
      title: "Köpprocess",
    },
    rumsbeskrivning: {
      pdf: rumsbeskrivning,
      title: "Rumsbeskrivning",
    },
    forkopsprocess: {
      pdf: forkopsprocess,
      title: "Förköpsprocess",
    },
  }

  return (
    <DocumentListStyle>
      <H2>Bofakta</H2>
      <div className="container">
      {Object.keys(pdf).map(key => {
        return <Document pdf={pdf[key].pdf} title={pdf[key].title} />
      })}
      </div>
    </DocumentListStyle>
  )
}

const DocumentListStyle = styled.section`
  padding: 4rem 2rem 2rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  h2 {
    text-align: center;
    color: #736459;
    max-width:100%;
    display: flex;
    justify-content: center;
  }

  @media only screen and (min-width: 1250px) {
    padding: 4rem 2rem 4rem 2rem;
  }
`

export default DocumentList
