import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Lightbox from "./Lightbox"
import styled from "styled-components"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      ext1: file(relativePath: { eq: "exterior/Exterior.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ex2: file(relativePath: { eq: "exterior/Exterior3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ex4: file(relativePath: { eq: "exterior/Exterior4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      terrass: file(relativePath: { eq: "exterior/Terrass.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      innergard: file(relativePath: { eq: "exterior/Innergard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kalkbrottet: file(relativePath: { eq: "kalkbrottet/kalkbrottet_big.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      vardagsrum: file(relativePath: { eq: "interior/Vardagsrum.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      kok: file(relativePath: { eq: "interior/Kok.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      badrum: file(relativePath: { eq: "interior/Badrum.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      extFlipped: file(relativePath: { eq: "exterior/Exterior2_flipped.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    }
  `)
  return (
    <Container>
      {Object.keys(data).map(key => {
        return (
          <Lightbox
            key={key}
            classPrefix={key}
            image={data[key].childImageSharp.fluid}
          />
        )
      })}
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 30vw);
  grid-gap: 1px;
  
  .lightbox-item {
    cursor: pointer;
    transition: ease 0.3s;
    &:hover {
      filter: brightness(70%);
    }
  }

  .grid-item-ex4 {
    grid-column: 3 / 5;
  }
  
  .grid-item-vardagsrum {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  .grid-item-kok {
    grid-column: 3 / 5;
    grid-row: 2;
  }
  
  .grid-item-innergard {
    grid-column: 3 / 5;
    grid-row: 3 / 4;
  }

  .grid-item-extFlipped {
    grid-column:3 / 5;
    grid-row: 4/5;
  }

  .grid-item-kalkbrottet {
    grid-column: 1 / 3;
    grid-row: 4;
  }

 

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    .grid-item {
      grid-column: 1;
      grid-row: unset;
    }
  }
`
export default Gallery
