import React from 'react'
import styled from 'styled-components'
import ButtonInterest from "./ButtonInterest" 
import Img from "gatsby-image"

const Interest = props => {
    return (
        <Container className="broker">
        
        <div className="head-container">
        <h3>Intresseanmälan</h3>
        </div>
        <div>
            <ButtonInterest link="mailto:ebba.jonsson@svenskfast.se?subject=Intresseanmälan Kalcium" title="Anmäl intresse här!" />
        </div>
    </Container>
    )
}

const Container = styled.div`
  background-color: rgba(255,255,255,0.85);
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding:

  p {
    margin-bottom: 0;
  }

  .head-container {
    position: absolute;
    left: 20px;
    bottom: 0;
    transform: rotate(270deg);
    transform-origin: 0 0;
  }

  h3 {
    position: relative;
    font-size: 80px;
    line-height: 80px;
    font-weight: 500;
    margin-bottom: 0;
    color: #736459;
  }


  .container {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    p {
      position: relative;
      top: -4px;
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -6px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #000;
  }

  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .container input:checked ~ .checkmark {
    background-color: #736459;
    color: #fff;
  }

  .grid-item-type .container {
    margin-bottom: 0;
    position: relative;
    top: 5px;
    font-size: 16px;
    margin-right: 0 !important;

    p {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 1250px) {
    display: block;
    padding: 2rem;

    .spinner {
      img {
        transform: translate(0, -50%);
      }
    }
    .formInputs {
      margin-left: auto;
      margin: auto;
      max-width: 960px;

      .grid-item-rok {
        grid-column: 3 / 6;
      }
      .grid-item-palce {
        grid-column: 1 / 3;
      }
    }
    
    .grid-item-agreement label {
      position: relative;
      top: 12px;
    }

    .head-container {
      transform: unset;
      position: unset;

      h3 {
        font-size: 50px;
        padding-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 750px) {
    .head-container h3 {
      font-size: calc(30px + (100 - 30) * ((100vw - 300px) / (1600 - 300)));
    }
    .spinner {
        grid-column: 1;
        img {
          transform: translate(0, -50%);
        }
    }
    .formInputs {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(7, 60px) 1fr 1fr 1fr 60px;
      padding: 0;

      .grid-item-name {
        grid-column: 1;
        grid-row: 1;
      }

      .grid-item-phone {
        grid-column: 1;
        grid-row: 2;
      }
      .grid-item-address {
        grid-column: 1;
        grid-row: 3;
      }
      .grid-item-email {
        grid-column: 1;
        grid-row: 4;
      }
      .grid-item-postAddress {
        grid-column: 1;
        grid-row: 5;
      }
      .grid-item-age {
        grid-column: 1;
        grid-row: 6;
      }
      .grid-item-place {
        grid-column: 1;
        grid-row: 7;
      }
      .grid-item-rok {
        grid-column: 1;
        grid-row: 8;
        display: flex;
        flex-direction: column;

        p {
          margin-right: 0;
        }
      }

      .grid-item-type {
        grid-column: 1;
        grid-row: 9;
        display: flex;
        flex-direction: column;

        label p {
          position: relative;
          top: -6px;
          margin-right: 20px;
        }

        p {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
      .grid-item-agreement {
        grid-column: 1;
        grid-row: 10;
      }
      .grid-item-submit {
        grid-column: 1;
        grid-row: 11;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding:4.5rem 1rem 5rem;
  }
`



export default Interest

