import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { CSSTransition } from "react-transition-group"

const Lightbox = props => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [showLightbox, setShowLightbox] = useState(false)
  const { classPrefix, image } = props

  const showTheLightbox = image => {
    setSelectedImage(image)
    setShowLightbox(true)
  }

  const hideTheLightbox = () => {
    setSelectedImage(null)
    setShowLightbox(false)
  }

  return (
    <div className={`grid-item grid-item-${classPrefix}`}>
      <LightboxContainer
        onClick={() => {
          showTheLightbox(image)
        }}
      >
        <Img className="lightbox-item" fluid={image} />
      </LightboxContainer>
      {showLightbox && (
        <Popup id="popup" onClick={hideTheLightbox}>
          <CSSTransition in={showLightbox} timeout={500} classNames="my-node">
            <div className="popupContnet">
              <Img fluid={selectedImage} />
              <span className="cross"></span>
            </div>
          </CSSTransition>
        </Popup>
      )}
    </div>
  )
}

const LightboxContainer = styled.div`
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 100%;
  }

  .popupContnet {
    position: relative;
    max-width: 80%;
    height: 50vw;
    cursor: pointer;

    .cross {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 20px;
      height: 20px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(-135deg);
      cursor: pointer;

      &::before {
        cursor: pointer;
        content: "";
        position: absolute;
        right: calc(-100% - 2px);
        top: calc(-100% - 2px);
        width: 20px;
        height: 20px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(-180deg);
      }
    }
  }

  .gatsby-image-wrapper {
    margin: 0 auto;
    border: 2px solid #fff;
    left: 0;

    img {
      object-fit: cover !important;
      height: 100%;
      width: 100%;
    }
  }
`
export default Lightbox
