import React from 'react'
import styled from 'styled-components'
import Broker from './Broker'
import Interest from './Interest'

// change form import to ""../forms/Form" when Projekt-webb will launch
//import Form from '../forms/Form'
// import Form from '../forms/KopAnmalan'
// import Form from '../forms/CountdownView'


const FormSections = () => {
  return (
    <Container>
      <Interest/>
      <Broker/>
    </Container>
  )
}

const Container = styled.section`
display: flex;
justify-content: center;
min-height: 800px;
border-top: 1px solid #fff;

.form {
  width: 55%;
}
.broker {
  /*  45% when form is in place */
  width: 45%;
  width: 100%;
}

@media only screen and (max-width: 1250px) {
  min-height: 100%;
  flex-direction: column;

  .form, .broker {
    width: 100%;
  }
}
`
export default FormSections
