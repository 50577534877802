import React from "react"
import styled from "styled-components"

const Dokument = ({title, pdf}) => {

  return (
    <DokumentStyle>
      <h3>{title}</h3>
      <a target="_blank" rel="noreferrer" href={pdf}>Öppna i ny flik</a>
    </DokumentStyle>
  )
}

const DokumentStyle = styled.div`
  margin: 0.8rem;
  text-decoration: none;
  color: rgba(44,27,14,0.8);
  min-width: 220px;
  background-color: #eaeaea;
  padding: 1.2rem;
  border-radius: 5px;

  h3 {
    font-size: 20px;
    color: #736459;
  }

  a {
    color: #736459;
  }
`

export default Dokument
