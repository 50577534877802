import React from "react"
import Gallery from '../components/gallery/Gallery'
import Layout from "../components/layouts/layout"
import FormSections from "../components/reusableUi/FormSections"
import DocumentList from "../components/reusableUi/documents/DocumentList"
import SEO from "../components/seo"


const BilderDokument = () => {
  return (
    <Layout>
      <SEO title="Bilder och dokument" description="Kalcium är inte bara ett livsnödvändigt grundämne. Det är också ett nytt välplanerat och trivsamt bostadsrättsprojekt med 61 lägenheter och tre radhus på bästa läge precis intill det majestätiska naturreservatet Kalkbrottet i den nya stadsdelen Elinegård."/>
      <Gallery />
      <DocumentList />
      <FormSections />
    </Layout>
  )
}

export default BilderDokument
