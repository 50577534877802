import React from "react"
import styled from "styled-components"

const ButtonInterest = ( {icon, title, link} ) => {
  return (
    <Container href={link} target="_blank">
      {icon && <img src={icon} alt="icon" />}
      <p id="title">{title}</p>
    </Container>
  )
}

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(115 100 89 / 100%);
  padding: 35px 60px;
  min-width: 200px;
  flex: 1;
  color: white;
  background-color: rgb(115 100 89 / 100%);
  text-decoration: none;
  transition: ease 0.3s;
  font-size: 20px;

  p, img {
    margin-bottom: 0;
  }
  #title {
    margin: 0;
    font-size: 18px;
    letter-spacing: 1px;
  }
  img {
    margin-right: 10px;
    transition: ease 0.3s;
  }

  &:hover {
    background-color: rgb(115 100 89 / 90%);
    img {
      // transform: scale(1.2);
    }
  }
`

export default ButtonInterest
